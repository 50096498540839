
import { Options, prop, Vue } from "vue-class-component";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  options = prop<Array<any>>({
    type: Array,
    default: [],
  });
}
@Options({
  emits: ["update:modelValue", "input", "onResetForm", "onSelectOption"],
  components: {},
})
export default class CardSelectorV2 extends Vue.with(Props) {
  isSelected(option: any) {
    return option.value === this.modelValue.isCustomRate;
  }
  selectOption(option: any) {
    this.$emit("onResetForm");
    if (!option.disabled) {
      this.$emit("onSelectOption", option.value);
    }
  }
}
