
import { Options, prop, Vue } from "vue-class-component";
import { ClientRegistrationDetail } from "@/domain/entities/ClientRegistration";
import {
  formatDate,
  formatDateNormal,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import {
  fileExtensionFromUrl,
  fileNameFromUrl
} from "@/app/infrastructures/misc/Utils";
import PriceTypeForm from "../../../global-components/price-type-form.vue";
import { EditClientRegistrationApiRequestV2 } from "@/data/payload/api/ClientRegistrationApiRequest";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { DetailClientManagement } from "@/domain/entities/ClientManagement";
import downloadFile from "@/app/infrastructures/misc/common-library/DownloadFile";

class Props {
  isManagement = prop<boolean>({
    type: Boolean,
    required: false,
    default: false
  });
}

@Options({
  components: {
    PriceTypeForm
  }
})
export default class InfoDetailClient extends Vue.with(Props) {
  modelDialog = false;
  isUpdateRateConfirmation = false;
  updateRateDialog = false;

  form = {
    isCustomRate: "retail",
    isInsurance: false,
    insurance: "",
    customRates: "",
    priceRateStartDate: "",
    priceRateEndDate: "",
    originCities: [] as OptionsClass[],
    discount: "",
    versionName: "",
    archiveFile: "" as any,
    retailBasedDistrict: false,
    versionBasic: "" as any
  };

  error = {
    isCustomRate: false,
    isInsurance: false,
    insurance: false,
    customRates: false,
    priceRateStartDate: false,
    priceRateEndDate: false,
    originCities: false,
    discount: false,
    versionName: false,
    archiveFile: false,
    retailBasedDistrict: false,
    versionBasic: false
  };

  customPriceOption = [
    {
      disabled: false,
      label: "Harga retail",
      description: "Harga akan mengikuti harga retail",
      value: "retail"
    },
    {
      disabled: false,
      label: "Harga normal",
      description: "Harga akan mengikat selama periode harga yang ditentukan.",
      value: "normal"
    },
    {
      disabled: false,
      label: "Harga khusus",
      description:
        "Harga akan mengikuti harga kecamatan/kota yang Anda upload.",
      value: "khusus"
    },
    {
      disabled: false,
      label: "Harga kombinasi",
      description:
        "Harga akan mengikuti harga kecamatan/kota yang Anda upload & harga retail.",
      value: "kombinasi"
    }
  ];

  get detailData() {
    if (this.isManagement) {
      return ClientManagementController.detailClientManagement;
    }

    return ClientRegistrationController.clientRegistrationDetail;
  }

  get clientStatusUpdatedAt() {
    return formatDate(this.detailData.clientStatusUpdatedAt);
  }

  get clientCurrentRate() {
    return this.detailData?.clientCurrentRate;
  }

  get clientStatus() {
    return (
      this.detailData?.clientCurrentRate?.clientStatusCustomRate.toLowerCase() ||
      ""
    );
  }

  get periodeHarga() {
    if (this.isManagement) {
      const detailData = this.detailData as DetailClientManagement;
      return this.formateRatePeriod(
        detailData.clientCurrentRate.clientRateversionStartDate,
        detailData.clientCurrentRate.clientRateVersionEndDate
      );
    }

    return this.formateRatePeriod(
      (this.detailData as ClientRegistrationDetail).clientCurrentRate
        .clientRateVersionStartDate,
      (this.detailData as ClientRegistrationDetail).clientCurrentRate
        .clientRateVersionEndate
    );
  }

  get clientIsCustomRate() {
    return (
      (this.priceType.split("_")[0] === "custom" ||
        this.priceType.split("_")[0] === "combination") &&
      !!this.detailData.clientCurrentRate.clientFileCustomRate
    ) || (this.detailData.clientIsCustomRate && this.detailData.clientCurrentRate.clientRatePriceType.toLowerCase() === "legacy");
  }

  get isErrorFile() {
    return (
      this.detailData.clientCurrentRate.clientErrorFileCustomRate &&
      this.detailData.clientCurrentRate.clientErrorNameCustomRate !==
        "error_log_.csv"
    );
  }

  get priceType() {
    return this.detailData?.clientCurrentRate.clientRatePriceType || "";
  }

  get priceTypeRenewRates() {
    if (
      this.detailData?.clientRenewalRate?.clientVersionBaseRate &&
      this.detailData?.clientRenewalRate?.clientVersionCustomRate
    ) {
      return "mix";
    }
    if (
      !this.detailData?.clientRenewalRate?.clientVersionBaseRate &&
      this.detailData?.clientRenewalRate?.clientVersionCustomRate
    ) {
      return "custom";
    }
    return "basic";
  }

  get tipeKostumisasiHarga() {
    if (this.priceType.toLowerCase() === "retail") {
      return "Harga Retail";
    } else if (
      this.priceType.toLowerCase() === "normal" ||
      this.priceType.toLowerCase() === "normal_custom_3lc"
    ) {
      return "Harga Normal";
    } else if (
      this.priceType.toLowerCase() === "combination" ||
      this.priceType.toLowerCase() === "combination_custom_3lc"
    ) {
      return "Harga Kombinasi";
    } else if (
      this.priceType.toLowerCase() === "custom_client" ||
      this.priceType.toLowerCase() === "custom_client_retail_district"
    ) {
      return "Harga Khusus";
    } else if (this.priceType.toLowerCase().includes("legacy")) {
      return this.priceTypeRenewRates;
    } else {
      return "Harga retail";
    }
  }

  get hargaKhusus() {
    if (
      this.priceType.toLowerCase() === "custom_client" ||
      this.priceType.toLowerCase() === "custom_client_retail_district"
    ) {
      return this.detailData?.clientCurrentRate.clientVersionCustomRate || "-";
    }
    return "-";
  }

  get statusString() {
    switch (this.clientStatus.toLowerCase()) {
      case "waiting":
      case "requested":
        return "Menunggu";
      case "rejected":
        return "Ditolak";
      case "active":
        return "Aktif";
      case "nearly-expired":
      case "nearly_expired":
        return "Nearly Expired";
      case "expired":
        return "Expired";
      case "pending":
        return "Pending";
      case "uploading":
        return `upload ${this.uploadDataProcesed}`;
      default:
        return "-";
    }
  }

  get statusBgColor() {
    switch (this.clientStatus.toLowerCase()) {
      case "waiting":
      case "requested":
      case "uploading":
        return "#FEFAE8";
      case "rejected":
        return "#F8E9E9";
      case "active":
      case "pending":
      case "nearly-expired":
      case "nearly_expired":
      case "expired":
        return "#EBF8FF";
      default:
        return "#AEAEAE";
    }
  }

  get statusTextColor() {
    switch (this.clientStatus.toLowerCase()) {
      case "waiting":
      case "requested":
      case "uploading":
        return "#F09035";
      case "rejected":
        return "#B82025";
      case "pending":
      case "active":
      case "nearly-expired":
      case "nearly_expired":
      case "expired":
        return "#006644";
      default:
        return "-";
    }
  }

  get bannerColor() {
    switch (this.clientStatus.toLowerCase()) {
      case "waiting":
      case "requested":
      case "rejected":
      case "active":
      case "uploading":
        return "#006644";
      case "nearly-expired":
      case "nearly_expired":
        return "#FAB713";
      case "expired":
        return "#CA0000";
      default:
        return "#78787A";
    }
  }

  get showUbahHargaButton() {
    switch (this.clientStatus.toLowerCase()) {
      case "requested":
      case "waiting":
      case "rejected":
      case "uploading":
        return true;
      default:
        return false;
    }
  }

  get shouldShow3LCOrigin() {
    const isNormal = this.priceType.toLowerCase().includes("normal");
    const isCombination = this.priceType.toLowerCase().includes("combination");
    return isNormal || isCombination;
  }

  get perhitunganAsuransi() {
    const insuranceValuePercentage = this.detailData.clientCurrentRate.clientRateInsuranceValuePercentage
      .toString()
      .replace(".", ",");
    switch (
      this.detailData.clientCurrentRate.clientRateInsuranceType.toLowerCase()
    ) {
      case "":
        return "Retail (Tiering)";
      case "bundling":
        return "Bundling(No Insurance Fee)";
      case "custom":
        return "Custom Rates " + insuranceValuePercentage + "%";
      default:
        return "-";
    }
  }

  downloadAttachment() {
    const url = this.detailData?.clientCurrentRate.clientFileCustomRate || "";
    downloadFile(url, fileNameFromUrl(url), fileExtensionFromUrl(url));
  }

  // download file
  downloadCustomRateFile(): void {
    const currentRateData = this.isManagement
      ? ClientManagementController.detailClientManagement.clientCurrentRate
      : ClientRegistrationController.clientRegistrationDetail.clientCurrentRate;
    const url = currentRateData.clientFileCustomRate;
    const fileName = currentRateData.clientFileNameCustomRate;

    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    const fileExt = fileName.split(".").pop();
    downloadFile(url, newFileName, `.${fileExt}`);
  }

  downloadErrorFile(): void {
    const currentRateData = this.isManagement
      ? ClientManagementController.detailClientManagement.clientCurrentRate
      : ClientRegistrationController.clientRegistrationDetail.clientCurrentRate;
    const url = currentRateData.clientErrorFileCustomRate;
    const fileName = currentRateData.clientErrorNameCustomRate;

    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    const fileExt = fileName.split(".").pop();

    downloadFile(url, newFileName, `.${fileExt}`).catch((error: any) => {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Download file gagal!!!")
      );
    });
  }

  showAll() {
    this.modelDialog = true;
  }

  updateRate() {
    this.isUpdateRateConfirmation = true;
  }

  confirmUpdateRateConfirmation() {
    this.isUpdateRateConfirmation = false;
    this.updateRateDialog = true;
  }

  closeUpdateRateConfirmation() {
    this.isUpdateRateConfirmation = false;
  }

  formateRatePeriod(start: string, end: string) {
    const isSameMonthYear =
      formatDateNormal(start, "MMMM-YYYY") ===
      formatDateNormal(end, "MMMM-YYYY");

    return `${formatDateNormal(
      start,
      isSameMonthYear ? "DD" : "DD MMMM YYYY"
    )} - ${formatDateNormal(end, "DD MMMM YYYY")}`;
  }

  // get Price Type
  get priceTypeVal() {
    let priceTypeVal = "";
    const lowerCustomRate = this.form.isCustomRate.toLowerCase();
    if (lowerCustomRate === "retail") priceTypeVal = "retail";
    if (
      lowerCustomRate === "normal" &&
      this.form.originCities[0].value === "All"
    )
      priceTypeVal = "normal";
    if (
      lowerCustomRate === "normal" &&
      this.form.originCities[0].value !== "All"
    )
      priceTypeVal = "normal_custom_3lc";
    if (lowerCustomRate === "khusus" && !this.form.retailBasedDistrict)
      priceTypeVal = "custom_client";
    if (lowerCustomRate === "khusus" && this.form.retailBasedDistrict)
      priceTypeVal = "custom_client_retail_district";
    if (
      lowerCustomRate === "kombinasi" &&
      this.form.originCities[0].value === "All"
    )
      priceTypeVal = "combination";
    if (
      lowerCustomRate === "kombinasi" &&
      this.form.originCities[0].value !== "All"
    )
      priceTypeVal = "combination_custom_3lc";
    return priceTypeVal;
  }

  get isValidUpdateRate() {
    let result = false;
    if (this.form.isCustomRate === "retail") {
      if (
        !this.form.isInsurance ||
        (this.form.isInsurance &&
          this.form.insurance &&
          !this.error.customRates)
      )
        result = true;
    }
    if (this.form.isCustomRate === "normal") {
      if (
        (this.form.versionBasic &&
          this.form.originCities.length &&
          this.form.discount &&
          !this.error.discount &&
          !this.form.isInsurance) ||
        (this.form.versionBasic &&
          this.form.originCities.length &&
          this.form.discount &&
          !this.error.discount &&
          this.form.isInsurance &&
          this.form.insurance &&
          !this.error.customRates)
      )
        result = true;
    }
    if (this.form.isCustomRate === "khusus") {
      if (
        (this.form.versionName &&
          this.form.archiveFile.name &&
          !this.form.isInsurance) ||
        (this.form.versionName &&
          this.form.archiveFile.name &&
          this.form.isInsurance &&
          this.form.insurance &&
          !this.error.customRates)
      )
        result = true;
    }
    if (this.form.isCustomRate === "kombinasi") {
      if (
        (this.form.versionName &&
          this.form.versionBasic &&
          this.form.originCities.length &&
          this.form.discount &&
          !this.error.discount &&
          this.form.archiveFile.name &&
          !this.form.isInsurance) ||
        (this.form.versionName &&
          this.form.versionBasic &&
          this.form.originCities.length &&
          this.form.discount &&
          !this.error.discount &&
          this.form.archiveFile.name &&
          this.form.isInsurance &&
          this.form.insurance &&
          !this.error.customRates)
      )
        result = true;
    }
    return (
      result && !this.error.priceRateStartDate && !this.error.priceRateEndDate
    );
  }

  get isBasicRateBool() {
    return this.form.isCustomRate === "normal";
  }
  get isMixRateBool() {
    return this.form.isCustomRate === "kombinasi";
  }
  get isCustomRateBool() {
    return this.form.isCustomRate === "khusus";
  }

  get priceStartDate() {
    const startDate = new Date(this.detailData.clientBilling.startDate);
    startDate.setDate(startDate.getDate() + 1);
    return startDate;
  }

  async submitUpdateRate() {
    const detailData = this.detailData as ClientRegistrationDetail;
    const renewalRate: any = this.form;
    const payload = new EditClientRegistrationApiRequestV2({
      id: this.detailData.id,
      companyName: this.detailData.clientName,
      email: this.detailData.clientEmail,
      phone: `62${this.detailData.clientPhone}`,
      address: this.detailData.clientAddress,
      districtCode: this.detailData.clientDistrict?.code,
      taxNumber: this.detailData.clientTaxNumber,
      versionBasic:
        typeof this.form.versionBasic === "object"
          ? this.form.versionBasic.rateVersionName
          : this.form.versionBasic,
      isCustomRate: this.isMixRateBool,
      discount: this.form.discount,
      priceRateStartDate: formatDateNormal(
        this.form.isCustomRate === "retail"
          ? this.priceStartDate
          : this.form.priceRateStartDate,
        "YYYY-MM-DD"
      ),
      priceRateEndDate: formatDateNormal(
        this.form.isCustomRate === "retail"
          ? this.detailData.clientBilling.endDate
          : this.form.priceRateEndDate,
        "YYYY-MM-DD"
      ),
      versionName: renewalRate.versionName,
      archiveFile:
        typeof renewalRate.archiveFile === "object"
          ? renewalRate.archiveFile
          : "",
      isUpdateCustom:
        typeof renewalRate.archiveFile === "object" ? true : false,
      clientType:
        this.detailData.clientType === "Client Induk" ? "parent" : "branch",
      category: this.detailData.clientCategory,
      bankName: detailData.clientBankLabel,
      bankAccountName: detailData.clientBankName,
      bankAccountNumber: detailData.clientBankNumber,
      bankAccountEmail: detailData.clientBankEmail,
      bankAccountLabel: detailData.clientBankLabel,
      contactName: detailData.clientContact.clientContactName,
      contactJobTitle: detailData.clientContact.clientContactJobTitle,
      contactEmail: detailData.clientContact.clientContactEmail,
      contactPhone: `62${detailData.clientContact.clientContactPhone}`,
      posId: detailData.clientPartner.clientPartnerId,
      posCommission: detailData.clientPartner.clientPartnerCommissionAmount,
      posQuantifier: detailData.clientPartner.clientPartnerQuantifierCommission,
      isDo: this.detailData.clientIsDo,
      isCod: this.detailData.clientIsCod,
      clientBranchCodReturnReceiverName: this.detailData.clientCodReject
        .clientCodName,
      clientBranchCodReturnReceiverPhone: this.detailData.clientCodReject
        .clientCodPhone,
      clientBranchCodReturnReceiverAddress: this.detailData.clientCodReject
        .clientCodAddress,
      clientBranchCodReturnReceiverCityCode: this.detailData.clientCodReject
        .clientCodDistrict.cityCode,
      clientBranchCodReturnReceiverDistrictCode: this.detailData.clientCodReject
        .clientCodDistrict.code,
      clientBranchCodFee: Number(
        this.detailData.clientCodReject.clientCodCommission
      ),
      isCustomRateOnly: this.isCustomRateBool,
      billingEmail: this.detailData.clientBilling.email.join(","),
      billingPaymentPeriod: this.detailData.clientBilling.timeRangePayment,
      priceType: this.priceTypeVal,
      taxpayerAddress: this.detailData.clientTax.taxPayerAddress,
      taxpayerName: this.detailData.clientTax.taxPayerAddress,
      billingScheduleSo: this.detailData.clientBilling.billingSchedule,
      billingWaNumber: this.detailData.clientBilling.noWhatsApp,
      companySize: this.detailData.clientCompanySize,
      contractEndDate: formatDateNormal(
        this.detailData.clientBilling.endDate,
        "YYYY-MM-DD"
      ),
      contractStartDate: formatDateNormal(
        this.detailData.clientBilling.startDate,
        "YYYY-MM-DD"
      ),
      insuranceType: this.form.isInsurance
        ? this.form.insurance === "custom-rates"
          ? "custom"
          : "bundling"
        : "",
      letterCodeOrigin: this.form.originCities.length
        ? this.form.originCities
            .filter((opt: OptionsClass) => opt.name !== "Semua 3LC")
            .map((opt: OptionsClass) => opt.name)
            .join(",")
        : "",
      vaBankAccountName: this.detailData.bankSettlement.accountName,
      vaBankEmail: this.detailData.bankSettlement.email,
      vaBankLabel: this.detailData.bankSettlement.bankLabel,
      vaBankName: this.detailData.bankSettlement.bankName,
      vaBankNumber: this.detailData.bankSettlement.accountNo,
      insurancePercentage: this.form.isInsurance ? this.insurancePercentage : ""
    });

    MainAppController.showLoading();
    await ClientRegistrationController.editClientRegistrationV2(payload)
      .then(() => {
        this.updateRateDialog = false;
        MainAppController.setSnackbarMessage(
          `Perubahan harga klien induk “${this.detailData.clientName}” berhasil!`
        );
        MainAppController.setSnackbarTimeout(2000);
        MainAppController.setSnackbar(true);
        ClientRegistrationController.getDetailV2(this.detailData.id);
        this.onResetForm();
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            `Perubahan harga klien induk “${this.detailData.clientName}” gagal!`
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  get insurancePercentage() {
    return this.form.isInsurance && this.form.insurance === "custom-rates"
      ? Number(Number("0." + this.form.customRates).toFixed(2))
      : 0;
  }

  get lcOptions() {
    return this.detailData.rateVersionCustom3lc
      ? this.detailData.rateVersionCustom3lc.split(",")
      : [];
  }

  get rejectReasonMessage() {
    return (
      this.detailData.clientStatusUpdatedReason ||
      this.detailData.clientCurrentRate.clientStatusUpdatedReason
    );
  }

  get isPriceTypeCustom() {
    return (
      this.priceType.toLowerCase() === "custom_client" ||
      this.priceType.toLowerCase() === "custom_client_retail_district"
    );
  }

  get uploadDataProcesed() {
    return `${this.detailData.dataProcessedCurrent}/${this.detailData.totalDataCurrent}`;
  }

  onResetForm() {
    this.form = {
      archiveFile: "",
      customRates: "",
      discount: "",
      insurance: "",
      isCustomRate: "retail",
      isInsurance: false,
      originCities: [],
      priceRateEndDate: "",
      priceRateStartDate: "",
      retailBasedDistrict: false,
      versionBasic: "",
      versionName: ""
    };
  }

  get isClientFreeze() {
    return this.detailData.clientFreeze;
  }
}
