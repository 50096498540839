
import { Options, prop, Vue } from "vue-class-component";
import CardSelectorV2 from "./card-selector-v2.vue";
import Checkbox from "primevue/checkbox";
import Select from "@/app/ui/components/check-box/index.vue";
import { ClientRegistrationController } from "@/app/ui/controllers/ClientRegistrationController";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import ToggleV2 from "@/app/ui/components/toggle-v2/index.vue";
import { ConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";
import downloadFile from "@/app/infrastructures/misc/common-library/DownloadFile";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  error = prop<any>({
    required: true,
  });
  customPriceOption = prop<any[]>({
    default: () => [],
  });
  validateStartEndDate = prop<any>({
    required: true,
  });
}
@Options({
  emits: ["change", "update:modelValue", "update:error"],
  components: {
    CardSelectorV2,
    Checkbox,
    UploadFile,
    ToggleV2,
  },
})
export default class PriceTypeForm extends Vue.with(Props) {
  created() {
    this.fetchVersionRates("");
  }

  toggleBasedDistrict() {
    this.modelValue.retailBasedDistrict = !this.modelValue.retailBasedDistrict;
    this.$emit("update:modelValue", {
      ...this.modelValue,
      retailBasedDistrict: this.modelValue.retailBasedDistrict,
    });
  }

  onSelect(value: any) {
    this.$emit("update:modelValue", {
      ...this.modelValue,
      isCustomRate: value,
    });
  }

  onResetForm() {
    this.modelValue.versionName = "";
    this.modelValue.versionBasic = "";
    this.modelValue.discount = "";
    this.modelValue.priceRateStartDate = new Date(
      this.$moment()
        .add(1, "days")
        .toDate()
    );
    this.modelValue.priceRateEndDate = new Date(
      this.$moment()
        .add(2, "days")
        .toDate()
    );
    this.modelValue.archiveFile = "";
    this.modelValue.retailBasedDistrict = false;
    this.modelValue.isInsurance = false;
    this.modelValue.customRates = 2;
    this.modelValue.insurance = "";
    this.modelValue.originCities = [{ name: "Semua 3LC", value: "All" }];
    this.$emit("update:modelValue", {
      ...this.modelValue,
      versionBasic: "",
      isCustomRate: "kombinasi",
      discount: "",
      priceRateStartDate: new Date(
        this.$moment()
          .add(1, "days")
          .toDate()
      ),
      priceRateEndDate: new Date(
        this.$moment()
          .add(2, "days")
          .toDate()
      ),
      versionName: "",
      archiveFile: "",
      isInsurance: false,
      customRates: 2,
      insurance: "",
      originCities: [{ name: "Semua 3LC", value: "All" }],
      retailBasedDistrict: false,
    });
  }

  isOpenSelectInsurance = false;

  insurances = [
    {
      name: "Pilih tipe asuransi",
      value: "",
    },
    {
      name: "Custom Rates",
      value: "custom-rates",
    },
    {
      name: "Bundling (No Insurance Fee)",
      value: "bundling (No Insurance Fee)",
    },
  ];
  onOpenSelectInsurance() {
    this.isOpenSelectInsurance = true;
  }
  onCloseSelectInsurance() {
    this.isOpenSelectInsurance = false;
  }
  async onSelectInsurance(_: string, value: string) {
    this.onCloseSelectInsurance();
    this.modelValue.insurance = value;
    this.modelValue.customRates = 2;
    await this.$emit("update:modelValue", {
      ...this.modelValue,
      insurance: value,
      customRates: 2,
    });
    this.error.customRates = false;
    await this.$emit("update:error", { ...this.error, customRates: false });
  }

  errorCaptionCustomRates = "";
  validateCustomRates(value: string) {
    value = `0,${value}`.replace(/,/g, ".");
    if (Number(value) < 0.2) {
      this.error.customRates = true;
      this.errorCaptionCustomRates =
        "Custom rates minimum 0,2%, cek & ubah lagi.";
      this.$emit("update:error", { ...this.error, customRates: true });
      return;
    } else if (Number(value) > 0.99) {
      this.error.customRates = true;
      this.errorCaptionCustomRates =
        "Custom rates maximum 0,99%, cek & ubah lagi.";
      this.$emit("update:error", { ...this.error, customRates: true });
      return;
    } else {
      this.error.customRates = false;
      this.$emit("update:error", { ...this.error, customRates: false });
      return;
    }
  }

  templateFile = "Download.csv";
  templateFileName = "template-client-custom-rate.xlsx";
  templateFileLink = `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/template-bulk-booking-commission.xlsx`;
  // download file
  download(url: string, fileName: string) {
    const newFileName = fileName
      .split(".")
      .slice(0, -1)
      .join(".");
    downloadFile(url, newFileName, ".xlsx");
  }

  // version rates
  fetchVersionRates = debounce((search: string) => {
    ClientRegistrationController.getVersionRateList({
      search: search,
    });
  }, 250);

  get versionRates() {
    return ClientRegistrationController.versionRateList;
  }

  get loadingVersionRates() {
    return ClientRegistrationController.loadingVersionRateList;
  }

  formatFloat(value: string) {
    return value
      .replace(/[^0-9,.]+/g, "")
      .replace(/\.|\.,|,,|,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
  }

  validateDiscount(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0 || Number(value) > 30) {
      this.error.discount = true;
      this.$emit("update:error", { ...this.error, discount: true });
    } else {
      this.error.discount = false;
      this.$emit("update:error", { ...this.error, discount: false });
    }
  }

  // origin city
  fetchCity(search: string) {
    LocationController.getCityList({
      search: search,
      status: "active",
      page: 1,
      limit: 500,
      newEndpoint: true
    });
  }
  filterCity = debounce((search: string) => {
    if (search.length > 2 || !search) this.fetchCity(search);
  }, 250);
  get cityExcludeOptions() {
    return LocationController.cityData.cityData.map((e) => ({
      id: e.id,
      name: `${e.code} - ${e.name}`,
      value: `${e.code}`,
    }));
  }
  get cityOptions() {
    return [
      {
        name: "Semua 3LC",
        value: "All",
      },
      ...this.cityExcludeOptions,
    ];
  }
  get isLoadingCity() {
    return LocationController.isLoading;
  }

  // Error exist
  get isExist() {
    return ClientRegistrationController.isExist;
  }

  setIsExist(val: boolean) {
    ClientRegistrationController.setIsExist(val);
  }

  get columnToValidate() {
    return [
      {
        column: "origin_city_code",
        type: "string",
      },
      {
        column: "destination_city_code",
        type: "string",
      },
      {
        column: "service_type",
        type: "string",
      },
      {
        column: "product_name",
        type: "string",
      },
      {
        column: "start_weight",
        type: "number",
      },
      {
        column: "end_weight",
        type: "string",
      },
      {
        column: "publish_rate",
        type: "number",
      },
      {
        column: "shipping_surcharge",
        type: "number",
      },
      {
        column: "document_surcharge",
        type: "number",
      },
    ];
  }
}
