
import { Options, prop, Vue } from "vue-class-component";

class Props {
  modelValue = prop<boolean>({
    default: false
  });
  isConfirm = prop<boolean>({
    type: Boolean,
    default: false
  });
  disabled = prop<boolean>({
    default: false
  });
  title = prop<string>({
    default: ""
  });
  textActive = prop<string>({
    default: ""
  });
  textInactive = prop<string>({
    default: ""
  });
  styleActive = prop<string>({
    default: ""
  });
  styleInactive = prop<string>({
    default: ""
  });
  customLabel = prop<boolean>({
    default: false
  });
}

@Options({
  name: "Toggle"
})
export default class extends Vue.with(Props) {
  onChange() {
    if (!this.disabled) {
      this.$emit(
        "update:modelValue",
        this.isConfirm ? this.modelValue : !this.modelValue
      );
    }
  }
}
